import axios from 'axios';
import { serverDomain } from '../constans';
import { getAuth } from 'firebase/auth';

const apiAxiosInstance = axios.create({
  baseURL: serverDomain,
  headers: {},
  timeout: 60000,
});

apiAxiosInstance.interceptors.request.use(
  async (config) => {
    let originalRequest = config;

    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user?.uid;
    const idToken = await user?.getIdToken();

    if(!user || !idToken) {
      throw new Error('User is not authenticated');
    }

    // const token = await firebase.auth().currentUser?.getIdToken()!;
    originalRequest.headers['Authorization'] = 'Bearer ' + idToken;
    originalRequest.headers['ta-user-id'] = userId;
    originalRequest.headers['ta-account-id'] = localStorage.getItem('account');


    return await Promise.resolve(originalRequest);
  },
  (err) => {
    return Promise.reject(err);
  },
);
export default apiAxiosInstance;
