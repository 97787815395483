import React from "react";
import { signInWithGoogle } from "./firebase";

const GoogleSignInButton: React.FC = () => {
    return (
        <button
            onClick={signInWithGoogle}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 20px",
                backgroundColor: "#4285F4",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                transition: "background-color 0.3s ease",
              }}
              onMouseOver={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor = "#3367D6";
              }}
              onMouseOut={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor = "#4285F4";
              }}
   
        >
            <img
                src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
                alt="Google Logo"
                style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                }}
            />
            Sign In with Google
        </button>
    );
};

export default GoogleSignInButton;
