import { useEffect, useState } from 'react';
// import logo from './logo.svg';
import './App.css';
import GoogleSignInButton from './GoogleSignInButton';
import { authStateListener, signOutUser } from './firebase';
import ConnectAccounts from './ConnectAccounts';
import apiAxiosInstance from './utils/apiAxiosInstance';
import TriggerPubsubButton from './triggerPubsub';

function App() {
  const [user, setUser] = useState<any>(null);
  const [account, setAccount] = useState<any>(null);
  const [googleAccounts, setGoogleAccounts] = useState<any>([]);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = authStateListener((user) => {
      setUser(user); // Update user state
    });
    return () => unsubscribe; // Cleanup listener on unmount
  }, []);

  useEffect(() => {
    (async () => {
      if (user) {
        const account = (await apiAxiosInstance.get(`/account`))?.data;
        setAccount(account);
        if (account?.googleAccounts) {
          setGoogleAccounts(Object.values(account.googleAccounts));
        }
      }
    })();
  }, [user]);

  // const fetchData = async () => {
  //   if (user) {
  //     try {
  //       console.log('user', user);
  //       // const token = await user.getIdToken(); // Retrieve the user's token
  //       // const emailData = await getGmailData(token);
  //       // const calendarData = (await fetchCalendarEvents()) || [];

  //       // setEmails(emailData.messages || []);
  //       // setEvents(calendarData?.items || []);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (user) fetchData();
  // }, [user]);

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          {user ? (
            <div style={{ textAlign: 'center' }}>
              <h1>Welcome, {user.displayName}!</h1>
              <img
                src={user.photoURL}
                alt="User Avatar"
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  marginTop: '20px',
                }}
              />
              <p>Email: {user.email}</p>

              {account && <p>Account: {account.name}</p>}
              {googleAccounts.length ? (
                <>
                  <p>{googleAccounts.map((account: any) => account.name).join(', ')}</p>
                  <TriggerPubsubButton />
                </>
              ) : (
                <ConnectAccounts />
              )}
              <button
                onClick={signOutUser}
                style={{
                  marginTop: '20px',
                  padding: '10px 20px',
                  backgroundColor: '#f44336',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
              >
                Sign Out
              </button>
            </div>
          ) : (
            <>
              <h1>Google Sign-In with Firebase</h1>
              <GoogleSignInButton />
            </>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
