// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { serverDomain } from './constans';
import axiosInstance from './utils/apiAxiosInstance';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAYiR6o7WjgcW2H1Pvi_ovOWqdeEOFRh6Q',
  authDomain: 'telapp-me.firebaseapp.com',
  projectId: 'telapp-me',
  storageBucket: 'telapp-me.firebasestorage.app',
  messagingSenderId: '285519257671',
  appId: '1:285519257671:web:9d87f1a092c18c9d9b1162',
  measurementId: 'G-1R97FKRM2W',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const db = getFirestore();
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

async function getUserDoc(userId: string) {
  return await getDoc(doc(db, 'users', userId));
}
export const signInWithGoogle = async () => {
  let user = null;
  try {
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken;
    user = result?.user;
    console.log('User Info:', user);

    const userDoc = (await getUserDoc(user.uid)).data();
    if (userDoc) {
      if (localStorage.getItem('account') === null) {
        localStorage.setItem('account', userDoc?.accounts[0]?.accountId);
      }
      return user;
    }

    if (token) {
      const u = await axiosInstance.post(`${serverDomain}/auth/create-user`, user, {
        method: 'POST',
      });
      const { account } = u.data;
      localStorage.setItem('account', account.accountId);
    }
    return user;
  } catch (error) {
    await user?.delete();
    console.error('Error during sign-in:', error);
  }
};

export const authStateListener = (callback: (user: any) => void) => {
  onAuthStateChanged(auth, callback);
};

export const signOutUser = async () => {
  try {
    await signOut(auth);
    console.log('User signed out successfully.');
  } catch (error) {
    console.error('Error during sign-out:', error);
  }
};

export default auth;
