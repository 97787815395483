import * as React from 'react';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useState, useEffect } from 'react';
import apiAxiosInstance from './utils/apiAxiosInstance';

export default function ConnectAccounts() {
  const [services, setServices] = useState<any>({
    GmailModify: true,
    CalendarReadonly: true,
    ContactsReadonly: true,
  });

  // const handleChange = (option: string) => {
  //   setServices((prevState: any) => ({ ...prevState, [option]: checked }));
  // };

  useEffect(() => {
    console.log('services:', services);
  }, [services]);

  const options = [
    {
      label: 'Connect Gmail',
      value: 'GmailModify',
    },
    {
      label: 'Connect Calendar',
      value: 'CalendarReadonly',
    },
    {
      label: 'Connect Contacts',
      value: 'ContactsReadonly',
    },
  ];

  async function connect() {
    console.log('services:', services);
    const selectedServices = Object.entries(services)
    .filter(([key, value]) => value === true) // Filter entries with value `true`
    .map(([key]) => key)

    const url = await apiAxiosInstance.post(
      `/auth/google/get-url`,
      {
        services: selectedServices
      },
      {
        method: 'POST',
      },
    );
    const { authUrl } = url.data;
    window.location.href = authUrl;
  }
  return (
    <div>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            control={
              <Checkbox
                onChange={() =>
                  setServices((prevState: any) => ({
                    ...prevState,
                    [option.value]: !services[option.value],
                  }))
                }
                checked={services[option.value]}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      <Button variant="contained" color="primary" onClick={connect}>
        Connect
      </Button>
    </div>
  );
}

// import * as React from 'react';
// import { useButton } from '@mui/base/useButton';

// export default function App() {
//   const { getRootProps } = useButton();
//   return (
//     <button type="button" {...getRootProps()}>
//       Click Me
//     </button>
//   );
// }
