import Button from '@mui/material/Button';
import apiAxiosInstance from './utils/apiAxiosInstance';

export default function TriggerPubsubButton() {
  // const handleChange = (option: string) => {
  //   setServices((prevState: any) => ({ ...prevState, [option]: checked }));
  // };

  async function trigger() {
    try {
      await apiAxiosInstance.post(
        `/gmail/trigger-pubsub`,
        {},
        {
          method: 'POST',
        },
      );
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div>
      <Button variant="contained" color="primary" onClick={trigger}>
        Trigger Pubsub
      </Button>
    </div>
  );
}

// import * as React from 'react';
// import { useButton } from '@mui/base/useButton';

// export default function App() {
//   const { getRootProps } = useButton();
//   return (
//     <button type="button" {...getRootProps()}>
//       Click Me
//     </button>
//   );
// }
